import LeaveInfo from "../components/elements/LeaveInfo";
import Head from "next/head";
import Slider3 from "../components/slider/Slider3";
import Counters from "../components/elements/Counters";
import React, {useState, useEffect} from "react";
import {PopupModal} from "react-calendly";
import Image from "next/image";

function Home() {
    const [rootDoc, setRootDoc] = useState(null);
    const [bookMeeting, setBookMeeting] = useState({
        open: false,
        type: "",
    });

    useEffect(() => {
        setRootDoc(document.body);
    }, []);

    return (
        <>
            <Head>
                <meta charSet="utf-8"/>
                <link rel="shortcut icon" type="image/png" href="/assets/imgs/logos/favicon.png"/>
                <link rel="apple-touch-icon" href="/assets/imgs/logos/app/apple-touch-icon.png"/>
                <link
                    rel="apple-touch-icon"
                    sizes="48x48"
                    href="/assets/imgs/logos/app/x48.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="72x72"
                    href="/assets/imgs/logos/app/x72.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="96x96"
                    href="/assets/imgs/logos/app/x96.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="144x144"
                    href="/assets/imgs/logos/app/x144.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="168x168"
                    href="/assets/imgs/logos/app/x168.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="192x192"
                    href="/assets/imgs/logos/app/x192.png"
                />
                <meta name="msapplication-TileColor" content="#ffffff"/>
                <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="author" content="ANIVIA"/>
                <meta name="theme-color" content="#007aff"/>
                <link rel="manifest" href="/manifest.json"/>
                <meta
                    name="description"
                    content="ANIVIA te Heusden-Zolder helpt je met software toepassingen zoals websites, web applicaties en mobiele applicaties. Daarnaast bieden ze ook IT consultants aan."
                />
                <title>Anivia - IT Solutions</title>
            </Head>
            <section
                className="xl:bg-contain bg-top bg-no-repeat -mt-24 pt-24"
                style={{
                    backgroundImage: "url('assets/imgs/backgrounds/intersect.svg')",
                }}
            >
                <div className="container px-4 mx-auto">
                    <div className="pt-12 text-center">
                        <div className="max-w-2xl mx-auto mb-8">
                            <h2 className="text-3xl lg:text-5xl lg:leading-normal mb-4 font-bold font-heading animate__animated animate__fadeIn">
                                Inspiring And Innovating
                                <br/>
                                <span className="text-blue-500"> Our Way</span>
                            </h2>
                            <div className="text-blueGray-400 leading-relaxed animate__animated animate__fadeIn">
                                Wij zijn uw vertrouwde partner voor al uw{" "}
                                <strong className="text-blue-500">IT-behoeften</strong>.
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
                <div className="relative max-w-6xl mt-16 md:mt-8 mb-8 mx-auto">
                    <Image
                        priority={true}
                        height={592.8}
                        width={1152}
                        src="/assets/imgs/elements/pattern.webp"
                        alt="Grafische patronen"
                    />
                    <div
                        className="absolute"
                        style={{top: "0", width: "100%", height: "66%"}}
                    >
                        <div
                            className="relative max-w-6xl mx-auto animate__animated animate__fadeIn"
                            data-wow-delay=".1s"
                        >
                            <Image priority={true} width={1152} height={682.55} src="/assets/imgs/elements/macbook.webp"
                                   alt="Macbook"/>
                            <div
                                className="absolute"
                                style={{
                                    top: "5.8%",
                                    left: "14.6%",
                                    width: "72.8%",
                                    height: "76.7%",
                                }}
                            >
                                <div className="relative w-full">
                                    <div
                                        className="carausel-fade-2 slick-carausel dot-style-1"
                                        id="carausel-fade-2-1"
                                    >
                                        <Slider3/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Counters></Counters>
            </section>

            <section
                className="pt-8 pb-12 md:py-16 lg:py-16 overflow-x-hidden"
                id="key-features"
            >
                <div className="container px-4 mx-auto">
                    <div className="flex flex-wrap lg:flex-nowrap">
                        <div className="w-full lg:w-1/2">
                            <div
                                className="lg:py-6 lg:pr-77 animate__animated animate__fadeIn"
                                data-wow-delay=".1s"
                            >
                                <div className="mb-4">
                                    <h2
                                        className="text-4xl mt-5 font-bold font-heading animate__animated animate__fadeIn"
                                        data-wow-delay=".1s"
                                    >
                                        Belangrijkste kenmerken{" "}
                                    </h2>
                                </div>
                                <div
                                    className="flex items-start py-4 animate__animated animate__fadeIn"
                                    data-wow-delay=".1s"
                                >
                                    <div className="w-8 mr-5 text-blue-500">
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                                            />
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="mb-2 text-xl font-semibold font-heading">
                                            Technologieën
                                        </h3>
                                        <p className="text-blueGray-400 leading-loose">
                                            We werken met de nieuwste en meest geavanceerde frameworks
                                            en tools.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="flex items-start py-4 animate__animated animate__fadeIn"
                                    data-wow-delay=".1s"
                                >
                                    <div className="w-8 mr-5 text-blue-500">
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
                                            />
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="mb-2 text-xl font-semibold font-heading">
                                            Compatibiliteit
                                        </h3>
                                        <p className="text-blueGray-400 leading-loose">
                                            Wij bieden goede ondersteuning voor onze producten. Onze
                                            software is eenvoudig te installeren en compatibel met
                                            alle soorten apparaten.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="flex items-start py-4 animate__animated animate__fadeIn"
                                    data-wow-delay=".1s"
                                >
                                    <div className="w-8 mr-5 text-blue-500">
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
                                            />
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="mb-2 text-xl font-semibold font-heading">
                                            Kwaliteit
                                        </h3>
                                        <p className="text-blueGray-400 leading-loose">
                                            Door mobiele, web- en bedrijfssoftware te ontwikkelen,
                                            stellen we u in staat uw concurrent een stap voor te zijn.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative w-full lg:w-1/2 my-12 lg:my-0">
                            <div
                                className="animate__animated animate__fadeIn"
                                data-wow-delay=".1s"
                            >
                                <Image
                                    width={584}
                                    height={584}
                                    className="jump relative mx-auto rounded-xl z-10"
                                    src="/assets/imgs/placeholders/img-1.webp"
                                    alt="Foto van groep mensen"
                                />
                                <img
                                    className="absolute top-0 left-0 w-40 -ml-12 -mt-12"
                                    src="/assets/imgs/elements/blob-tear.svg"
                                    alt="Dots"
                                />
                                <img
                                    className="absolute bottom-0 right-0 w-40 -mr-12 -mb-12"
                                    src="/assets/imgs/elements/blob-tear.svg"
                                    alt="Dots"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-16 pb-20" id="key-features">
                <div className="container">
                    <div className="flex flex-wrap items-center mb-12">
                        <div className="w-full lg:w-1/2 mb-6 lg:mb-0">
                            <h2
                                className="text-4xl font-bold font-heading animate__animated animate__fadeIn"
                                data-wow-delay=".1s"
                            >
                                <span>We creëren </span>
                                <br/>
                                <span className="text-blue-600">digitale ervaringen </span>
                                <span>die het leven van mensen vergemakkelijken</span>
                            </h2>
                        </div>
                        <div className="w-full lg:w-1/2">
                            <p
                                className="lg:pl-16 text-blueGray-400 leading-loose animate__animated animate__fadeIn"
                                data-wow-delay=".1s"
                            >
                                We helpen onze klanten bij het definiëren, ontwerpen,
                                ontwikkelen en leveren van digitale producten en diensten die
                                zinvol en waardevol zijn voor hun gebruikers.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 -mb-6">
                        <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
                            <div
                                className="border border-gray-100 pt-8 px-6 pb-6 bg-white text-center rounded shadow hover:shadow-lg hover-up-2 transition duration-500 animate__animated animate__fadeIn"
                                data-wow-delay=".1s"
                            >
                                <div className="text-blue-500 mx-auto mb-4">
                                    <svg
                                        className="w-8 h-8 mx-auto"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                                        />
                                    </svg>
                                </div>
                                <h3 className="mb-2 font-bold font-heading">IT Consultancy</h3>
                                <p className="text-sm text-blueGray-400">
                                    Wij zijn een team van ervaren consultants, ontwikkelaars en
                                    ontwerpers die nauw samenwerken met onze klanten om hen te
                                    helpen hun digitale doelen te bereiken.
                                </p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
                            <div
                                className="border border-gray-100 pt-8 px-6 pb-6 bg-white text-center rounded shadow hover:shadow-lg hover-up-2 transition duration-500 animate__animated animate__fadeIn"
                                data-wow-delay=".1s"
                            >
                                <div className="text-blue-500 mx-auto mb-4">
                                    <svg
                                        className="w-8 h-8 mx-auto"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                        />
                                    </svg>
                                </div>
                                <h3 className="mb-2 font-bold font-heading">Web Development</h3>
                                <p className="text-sm text-blueGray-400">
                                    Wij zijn expert in webontwikkeling en doen dit al vele jaren.
                                    We leveren websites die gebruiksvriendelijk, responsief en
                                    informatief zijn voor onze klanten.
                                </p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
                            <div
                                className="border border-gray-100 pt-8 px-6 pb-6 bg-white text-center rounded shadow hover:shadow-lg hover-up-2 transition duration-500 animate__animated animate__fadeIn"
                                data-wow-delay=".1s"
                            >
                                <div className="text-blue-500 mx-auto mb-4">
                                    <svg
                                        className="w-8 h-8 mx-auto"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                        />
                                    </svg>
                                </div>
                                <h3 className="mb-2 font-bold font-heading">App Development</h3>
                                <p className="text-sm text-blueGray-400">
                                    We ontwikkelen mobiele applicaties die bedrijven helpen
                                    efficiënter te werken en de communicatie tussen werknemers op
                                    een andere niveau tillen.
                                </p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
                            <div
                                className="border border-gray-100 pt-8 px-6 pb-6 bg-white text-center rounded shadow hover:shadow-lg hover-up-2 transition duration-500 animate__animated animate__fadeIn"
                                data-wow-delay=".1s"
                            >
                                <div className="text-blue-500 mx-auto mb-4">
                                    <svg
                                        className="w-8 h-8 mx-auto"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                                        />
                                    </svg>
                                </div>
                                <h3 className="mb-2 font-bold font-heading">Digital support</h3>
                                <p className="text-sm text-blueGray-400">
                                    We bieden 24/7 digitale ondersteuning. Ongeacht het tijdstip,
                                    u kunt ons altijd bereiken via telefoon of e-mail. We zijn er
                                    altijd om je te helpen met alles wat je nodig hebt.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*<section className="py-20 bg-blue-500">*/}
            {/*    <div className="container">*/}
            {/*        <div className="text-center max-w-xl mx-auto">*/}
            {/*            <h2 className="mb-4 text-3xl lg:text-3xl text-white font-bold font-heading">*/}
            {/*                <span>Bereken uw project via onze</span>*/}
            {/*                <span className="text-blue-200"> calculator</span> <br/>*/}
            {/*            </h2>*/}
            {/*            <p className="mb-8 text-blueGray-200">De prijs is slechts een schatting. Neem contact op voor*/}
            {/*                meer details.</p>*/}
                        {/*<div className="flex justify-center mx-auto">*/}
                        {/*    <a href="https://calculator.anivia.be/" target="_blank"*/}
                        {/*       className="cursor-pointer block sm:inline-block py-4 px-6 text-xs text-blueGray-500 hover:text-blueGray-600 text-center font-semibold leading-none bg-white border border-blueGray-200 hover:border-blueGray-300 rounded">*/}
                        {/*        Bereken uw project*/}
                        {/*    </a>*/}
                        {/*</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <section
                className="py-20 xl:bg-contain bg-top bg-no-repeat"
                style={{
                    backgroundImage: "url('assets/imgs/backgrounds/intersect.svg')",
                }}
            >
                <div className="container px-4 mx-auto">
                    <div className="text-center mb-10">
                        <h2
                            className="max-w-lg mx-auto mb-4 text-4xl font-bold font-heading animate__animated animate__fadeIn"
                            data-wow-delay=".1s"
                        >
              <span>
                Huur vandaag nog de benodigde{" "}
                  <span className="text-blue-500"> IT </span> consultant in
              </span>
                        </h2>
                        <p
                            className="max-w-sm mx-auto text-lg text-blueGray-400 animate__animated animate__fadeInDown"
                            data-wow-delay=".1s"
                        >
                            Uur tarieven{" "}
                        </p>
                    </div>
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
                            <div
                                className="hover-up-5 pt-16 pb-8 px-4 text-center bg-white rounded shadow animate__animated animate__fadeIn"
                                data-wow-delay=".1s"
                            >
                                <Image
                                    width={80}
                                    height={80}
                                    className="mb-6 mx-auto"
                                    src="/assets/imgs/icons/startup.svg"
                                    alt="Man en vrouw met geld plant"
                                />
                                <h3 className="mb-2 text-4xl font-bold font-heading">
                                    Frontend Developer
                                </h3>
                                <p className="mt-2 mb-8 text-blueGray-400">
                                    {" "}
                                    <span className="text-4xl text-blue-500 font-bold font-heading">
                    €70.00
                  </span>{" "}
                                    excl. btw
                                </p>
                                <div className="flex flex-col items-center mb-8 p-5">
                                    <ul className="text-blueGray-400">
                                        <li className="flex mb-3">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>Angular</span>
                                        </li>
                                        <li className="flex mb-3">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>Flutter</span>
                                        </li>
                                        <li className="flex mb-3">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>React (Native)</span>
                                        </li>
                                        <li className="flex">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>VueJS</span>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <a
                                        className="cursor-pointer block sm:inline-block py-4 px-6 text-xs text-blueGray-500 hover:text-blueGray-600 text-center font-semibold leading-none bg-white border border-blueGray-200 hover:border-blueGray-300 rounded"
                                        onClick={() =>
                                            setBookMeeting({type: "Frontend", open: true})
                                        }
                                    >
                                        Boek meeting
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
                            <div
                                className="hover-up-5 pt-16 pb-8 px-4 text-center text-white bg-blue-500 rounded shadow animate__animated animate__fadeIn"
                                data-wow-delay=".1s"
                            >
                                <Image
                                    width={80}
                                    height={80}
                                    className="mb-6 mx-auto"
                                    src="/assets/imgs/icons/agency.svg"
                                    alt="Man die grafieken bekijkt"
                                />
                                <h3 className="mb-2 text-4xl font-bold font-heading">
                                    Backend Developer
                                </h3>
                                <p className="mt-2 mb-8">
                                    {" "}
                                    <span className="text-4xl font-bold font-heading">
                    €70.00
                  </span>{" "}
                                    excl. btw
                                </p>
                                <div className="flex flex-col items-center mb-8 p-5">
                                    <ul>
                                        <li className="flex items-center mb-3 ">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>Java</span>
                                        </li>
                                        <li className="flex items-center mb-3">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>Spring Boot</span>
                                        </li>
                                        <li className="flex items-center mb-3">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>NodeJS</span>
                                        </li>
                                        <li className="flex items-center">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>ASP.NET</span>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <a
                                        className="cursor-pointer block sm:inline-block py-4 px-6 mb-4 sm:mb-0 sm:mr-3 text-xs text-blue-500 font-semibold leading-none bg-white hover:bg-blueGray-50 rounded"
                                        onClick={() =>
                                            setBookMeeting({type: "Backend", open: true})
                                        }
                                    >
                                        Boek meeting
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 px-3 mb-6">
                            <div
                                className="hover-up-5 pt-16 pb-8 px-4 text-center bg-white rounded shadow animate__animated animate__fadeIn"
                                data-wow-delay=".1s"
                            >
                                <Image
                                    width={80}
                                    height={80}
                                    className="mb-6 mx-auto"
                                    src="/assets/imgs/icons/enterprise.svg"
                                    alt="Echte business man"
                                />
                                <h3 className="mb-2 text-4xl font-bold font-heading">
                                    Full-Stack Developer
                                </h3>
                                <p className="mt-2 mb-8 text-blueGray-400">
                                    {" "}
                                    <span className="text-4xl text-blue-500 font-bold font-heading">
                    €80.00
                  </span>{" "}
                                    excl. btw
                                </p>
                                <div className="flex justify-between items-center mb-8 p-5">
                                    <ul className="text-blueGray-400">
                                        <li className="flex mb-3">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>Java</span>
                                        </li>
                                        <li className="flex mb-3">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>Angular</span>
                                        </li>
                                        <li className="flex mb-3">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>React</span>
                                        </li>
                                        <li className="flex">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>Flutter</span>
                                        </li>
                                    </ul>
                                    <ul className="text-blueGray-400">
                                        <li className="flex mb-3">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>NodeJS</span>
                                        </li>
                                        <li className="flex mb-3">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>SQL</span>
                                        </li>
                                        <li className="flex mb-3">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>Firebase</span>
                                        </li>
                                        <li className="flex">
                                            <svg
                                                className="w-6 h-6 mr-2 text-green-500"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                            <span>MongoDB</span>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <a
                                        onClick={() =>
                                            setBookMeeting({type: "Full-Stack", open: true})
                                        }
                                        className="cursor-pointer block sm:inline-block py-4 px-6 mb-4 sm:mb-0 sm:mr-3 text-xs text-white text-center font-semibold leading-none bg-blue-400 hover:bg-blue-500 rounded"
                                    >
                                        Boek meeting
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LeaveInfo></LeaveInfo>
            {rootDoc && (
                <PopupModal
                    url="https://calendly.com/anivia/30min"
                    prefill={{
                        customAnswers: {
                            a1: `Type: ${bookMeeting.type} Developer`,
                        },
                    }}
                    pageSettings={{
                        hideEventTypeDetails: true,
                    }}
                    onModalClose={() => setBookMeeting({...bookMeeting, open: false})}
                    open={bookMeeting.open}
                    rootElement={rootDoc}
                />
            )}
        </>
    );
}

export default Home;
